import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import StandardBanner from "../../../components/StandardHero/StandardHero";
import WorkingWith from "../../../components/WorkingWith/WorkingWith";
import VideoFeaturePanel from "../../../components/VideoFeaturePanel/VideoFeaturePanel";
import Benefits from "../../../components/Benefits/Benefits";
import { GatsbyImage } from "gatsby-plugin-image";
import RelatedServices from "../../../components/RelatedServices/RelatedServices";
import Divider from "../../../components/Divider/Divider";

const ManagersPage = ({
  data,
  data: {
    banner,
    banner: {
      featurePanel,
      benefits: benefitsData,
      partners,
      services: relatedServicesData,
      Articles: articlesData,
      topics,
      client_types,
      filter_types,
    },
  },
}) => {
  const bannerImage =
    banner.hero.image.localFile.childImageSharp.gatsbyImageData;
  const bannerTitle = banner.hero.title;
  const showBreadcrumb = banner.hero.showBreadcrumb;

  const breadcrumbs = [{ name: "WHAT WE DO" }, { name: "AMXCONNECT" }];

  const workingWithData = {
    heading: "Who we're working with:",
    images: partners.map((image) => {
      let gatsbyImageData =
        image.logo.localFile.childImageSharp.gatsbyImageData;

      return (
        <GatsbyImage image={gatsbyImageData} alt={image.logo.alternativeText} />
      );
    }),
  };

  return (
    <Layout
      seo={{
        title: banner.metaData?.pageTitle,
        description: banner.metaData?.metadescription,
        image: bannerImage,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <VideoFeaturePanel data={featurePanel} />
      <Benefits benefitsData={benefitsData} faqLink={data.faqLink} />
      <Divider />
      <WorkingWith {...workingWithData} />
      <Divider />
      <RelatedServices
        relatedServicesData={relatedServicesData}
        articlesData={articlesData}
        tagTypes={filter_types}
        topics={topics}
        client_types={client_types}
      />
    </Layout>
  );
};

export default ManagersPage;

export const query = graphql`
  {
    banner: strapiSolutionsAmxconnectOverview {
      topics {
        id
        type
      }
      client_types {
        id
        Type
      }
      filter_types {
        type
      }
      hero {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        showBreadcrumb
      }
      faqLink {
        faq_category {
          category
        }
        text
      }
      metaData {
        pageTitle
        metadescription
      }

      Articles {
        articlesHeading
        insights {
          id
        }
        case_studies {
          id
        }
        press_releases {
          id
        }
        events {
          id
        }
      }
      services {
        shortDesc
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            absolutePath
          }
        }
      }
      partners {
        name
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 100
                quality: 100
                webpOptions: { quality: 100 }
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
      benefits {
        Copy
        Title
        Icon {
          localFile {
            publicURL
          }
        }
      }
      featurePanel {
        copy
        heading
        headerImage {
          localFile {
            publicURL
          }
        }
        video {
          video {
            localFile {
              publicURL
            }
          }
          transcript {
            localFile {
              id
              publicURL
            }
          }
        }
        mainImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
